.download {
  padding: 6px 20px;
  background-color: white;
  /*color: #fd0167;*/
  color: black;
  /*border: 1px solid #fd0167;*/
  border: 1px solid black;
  margin-left: 0;
  margin-right: 0;
}

.download:hover {
  cursor: pointer;
  background-color: #fd0167;
  color: white;
}

.sortArrows {
  display: flex;
  flex-direction: column;
  opacity: 0.5;
}

.sortArrows-active {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .download {
    margin-top: 20px;
  }
}
